import { ChakraBaseProvider } from '@chakra-ui/react'
import { Global } from '@emotion/react'
import type { HeadFC, PageProps } from 'gatsby'
import * as React from 'react'
import theme from '../@chakra-ui/gatsby-plugin/theme'
import { useHome } from '../gatsby-source-sanity'

const Layout: React.FC<PageProps> = ({ children }) => {
  const { headerColorTheme } = useHome()
  // headerColorTheme?.background?.hex get's overrode by theme file
  return (
    <React.Fragment>
      <Global
        styles={`
          @font-face {
            font-family: 'Lausanne 300';
            src: url('../../fonts/TWKLausanne-300.woff2') format('woff2'),
                url('../../fonts/TWKLausanne-300.woff') format('woff');
            font-weight: 300;
            font-style: normal;
            font-display: swap;
        }
        `}
      />
      <ChakraBaseProvider theme={theme}>{children}</ChakraBaseProvider>
    </React.Fragment>
  )
}

export default Layout
