import { extendBaseTheme } from '@chakra-ui/react'
// `@chakra-ui/theme` is a part of the base install with `@chakra-ui/react`
import chakraTheme from '@chakra-ui/theme'

const { Button, Link, Heading } = chakraTheme.components

const Text = {
  baseStyle: {
    fontFamily: 'body',
    fontWeight: 400
  },
  sizes: {
    sm: {
      fontSize: { base: 'xs', md: 'sm' },
      lineHeight: { base: 'xs', md: 'sm' }
    },
    lg: {
      fontSize: { base: 'md', md: 'lg' },
      lineHeight: { base: 'md', md: 'lg' }
    }
  },
  variants: {
    uppercase: {
      textTransform: 'uppercase',
      letterSpacing: '0.02em'
    }
  },
  defaultProps: {
    size: 'sm'
  }
}

const breakpoints = {
  sm: '30em',
  md: '48em',
  lg: '64em', // 1024px
  xl: '80em',
  '2xl': '96em'
}

const theme = extendBaseTheme({
  styles: {
    global: {
      html: {},
      body: {
        bg: '#BF6E4B'
      }
    }
  },
  fonts: {
    body: `'Lausanne 300', sans-serif`
  },
  colors: {
    white: '#FFF',
    black: '#000'
  },
  fontSizes: {
    xs: '14px',
    sm: '17px',
    md: '23px',
    lg: '40px'
  },
  lineHeights: {
    xs: '1.40',
    sm: '1.35',
    md: '1.20',
    lg: '1.26'
  },
  components: {
    Button,
    Link,
    Heading,
    Text
  },
  breakpoints
})

// @TODO: look into this
// warn ./node_modules/@chakra-ui/gatsby-plugin/src/provider.js
// Attempted import error: 'baseTheme' is not exported from './theme' (imported as 'baseTheme').

export default theme
