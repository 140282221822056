import { graphql, useStaticQuery } from 'gatsby'

const useSettings = () => {
  const { settings } = useStaticQuery(
    graphql`
      query {
        settings: sanitySettings {
          seo {
            title
            description
            image {
              asset {
                url
              }
            }
          }
        }
      }
    `
  )
  return settings
}

export default useSettings
