import { graphql, useStaticQuery } from 'gatsby'

const useHome = () => {
  const { home } = useStaticQuery(
    graphql`
      query {
        home: sanityHome {
          _rawMedia(resolveReferences: { maxDepth: 10 })
          email
          instagram
          headerColorTheme {
            background {
              hex
            }
          }
          footerColorTheme {
            background {
              hex
            }
          }
        }
      }
    `
  )
  return home
}

export default useHome
